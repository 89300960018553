import { useRouter } from 'next/router';
import { signIn } from '../../../../lib/userActions';

const useLogin = () => {
  const router = useRouter();

  const onLogin = async (username: string, password: string) => {
    const result = await signIn(username, password);
    if (!result?.message) {
      if (router?.query?.redirect) {
        await router.replace(router.query.redirect as string);
      } else {
        await router.replace('/select-organization');
      }
    }
    return result;
  };

  return {
    operations: { onLogin },
  };
};

export default useLogin;
