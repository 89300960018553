import { SigninActions } from '@modules/SignIn/common/consts';
import { useState } from 'react';

type FormValues = {
  username: string,
  password: string,
};

interface ResponseProp {
  message?: string;
}

const useLogin = ({ actions }: SigninActions) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const onSubmit = ({ username, password }: FormValues) => {
    setLoading(true);
    setError('');
    actions.onLogin(username, password)
      .then((res: ResponseProp) => {
        if (res?.message) {
          setError(res?.message);
        }
        setLoading(false);
      })
      .catch((err: Error) => {
        setError(err.message);
        setLoading(false);
      });
  };

  const deleteServerError = () => {
    setError('');
  };

  return {
    operations: { onSubmit, deleteServerError },
    models: { error, loading },
  };
};

export default useLogin;
