import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Body, Button, Icons } from '@jobdone/jobdone-web-design-system';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import PageFooterWithTermsAndLangChange from '@components/PageFooterWithTermsAndLangChange';

export interface EmailLayoutProps {
  children: React.ReactNode;
  mode?: 'signIn' | 'signUp';
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  intro: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexBasis: '30%',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      flexBasis: '30%',
    },
  },
  introBody: {
    width: '90%',
    userSelect: 'none',
    color: theme.palette.grey[300],
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  introBodyMarginTop: {
    marginTop: 8,
  },
  logo: {
    '&>svg': {
      width: '100%',
    },
    margin: 30,
    [theme.breakpoints.down('sm')]: {
      margin: 10,
    },
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      width: 105,
      top: 15,
      left: 24,
      margin: 0,
    },
    '@media (max-width:340px)': {
      position: 'absolute',
      width: 105,
      top: -13,
      left: 0,
      margin: 0,
    },
  },
  whiteContainer: {
    position: 'relative',
    backgroundColor: '#fff',
    borderRadius: 24,
    margin: '24px 24px 24px 0',
    flexBasis: '70%',
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
      margin: 0,
      borderRadius: '0 32px 0 0',
    },
    overflowY: 'auto',
  },
  emailActionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'unset',
      justifyContent: 'center',
    },
  },
  emailActions: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  footer: {
    marginBottom: 'auto',
    marginRight: 10,
    marginLeft: 10,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'end',
      flexGrow: 1,
      marginBottom: 15,
    },
  },
  haveAccount: {
    position: 'absolute',
    color: '#DADDE4',
    top: 50,
    right: 50,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      top: 35,
      right: 23,
      '& > p': {
        color: '#DADDE4',
      },
      '& > button': {
        padding: 0,
        background: 'none',
        width: 'initial',
        color: '#DADDE4',
        marginLeft: 5,
        textDecoration: 'underline !important',
        '&:hover': {
          background: 'none',
          color: '#DADDE4',
        },
        '&:active': {
          background: 'none',
          color: '#DADDE4',
        },
      },
    },
  },
  haveAccountText: {
    color: theme.palette.grey[700],
  },
  actionBtn: {
    width: 100,
    marginLeft: 10,
  },
}));

export const EmailLayout = ({
  children,
  mode = 'signUp',
}: EmailLayoutProps) => {
  const styles = useStyles();
  const router = useRouter();
  const { t } = useTranslation(['register']);

  return (
    <div className={styles.root}>
      <div className={styles.intro}>
        <Icons.Logo aria-label='logo' className={styles.logo} />
        <Body
          aria-label='body-primary'
          variant='large'
          className={styles.introBody}
        >
          {t('register:welcome_body_primary')}
        </Body>
        <Body
          aria-label='body-secondary'
          variant='large'
          className={cx(styles.introBody, styles.introBodyMarginTop)}
        >
          {t('register:welcome_body_secondary')}
        </Body>
      </div>
      <div className={styles.whiteContainer}>
        <div className={styles.emailActionsContainer}>
          <div className={styles.emailActions}>{children}</div>
          <div className={styles.footer}>
            <PageFooterWithTermsAndLangChange />
          </div>
        </div>
      </div>
      <div className={styles.haveAccount}>
        {mode === 'signUp' && (
          <>
            <Body
              aria-label='body-hasAccount'
              variant='medium'
              className={styles.haveAccountText}
            >
              {t('register:already_have_account')}
            </Body>
            <Button
              aria-label='logIn-btn'
              label={t('register:login')}
              variant='fixedLabel'
              color='secondary'
              size='small'
              className={styles.actionBtn}
              onClick={() => router.push('/login')}
            />
          </>
        )}
        {/* {mode === 'signIn' && (
          <>
            <Body
              aria-label='body-newToJobdone'
              variant='medium'
              className={styles.haveAccountText}
            >
              {t('register:new_to_jobdone')}
            </Body>
            <Button
              aria-label='register-btn'
              label={t('register:sign_up')}
              variant='fixedLabel'
              color='secondary'
              size='small'
              className={styles.actionBtn}
              onClick={() => router.push('/register')}
            />
          </>
        )} */}
      </div>
    </div>
  );
};
