/* eslint-disable @typescript-eslint/no-floating-promises */
import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';
import { useAuth } from '@common/hooks';
import { useRouter } from 'next/router';
import LoginContainer from '@modules/SignIn/Login.container';
import PageLoader from '@components/PageLoader';
import { PageLanguageProps } from '@common/interfaces';
import { useTranslation } from 'next-i18next';

const LoginPage = () => {
  const router = useRouter();
  const { data, error } = useAuth();
  const { t } = useTranslation(['common']);
  const orgId = typeof window !== 'undefined' ? localStorage.getItem('organizationId') : null;

  if (orgId) {
    router.push('/liveqr');
  }

  if (data) {
    if (router?.query?.redirect) {
      router.replace(router.query.redirect as string);
    } else {
      router.push('/select-organization');
    }
  }

  if (error) {
    return (
      <>
        <Head>
          <title>
            JobDone -
            {' '}
            {t('common:login')}
          </title>
        </Head>
        <LoginContainer />
      </>
    );
  }

  return <PageLoader />;
};

export async function getServerSideProps(props: PageLanguageProps) {
  const { locale } = props;

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'login', 'register', 'validations'])),
    },
  };
}

export default LoginPage;
