import React from 'react';
import { EmailLayout } from '@components/EmailLayout/EmailLayout';
import { useLogin } from './models';
import { Login } from './components';

const LoginContainer = () => {
  const { operations } = useLogin();

  return (
    <EmailLayout mode='signIn'>
      <Login
        actions={{ onLogin: operations.onLogin }}
      />
    </EmailLayout>
  );
};

export default LoginContainer;
