import { makeStyles } from '@material-ui/core';
import { Body } from '@jobdone/jobdone-web-design-system';
import StaggerParent from '@common/animations/StaggerParent';
import FadeInOut from '@common/animations/FadeInOut';
import FadeInUp from '@common/animations/FadeInUp';
import LangChanger from './LangChanger';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    gap: 32,
    marginBottom: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      gap: 14,
      flexDirection: 'column',
    },
    '@media (max-width:340px)': {
      gap: 5,
      flexDirection: 'column',
    },
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 24,
    '@media (max-width:340px)': {
      gap: 0,
      margin: '0 20px',
    },
  },
  link: {
    transition: 'all .2s ease',
    color: theme.palette.grey[700],
    textDecoration: 'none',
    '&:hover': {
      color: '#4589ff',
      textDecoration: 'underline',
    },
    '&:active': {
      color: '#3467bf',
    },
  },
}));

const PageFooterWithTermsAndLangChange = () => {
  const styles = useStyles();

  return (
    <FadeInOut>
      <StaggerParent delay={0.05}>
        <div className={styles.root}>
          <div className={styles.flexContainer}>
            <FadeInUp>
              <Body
                component='span'
                variant='small'
                display='inline'
              >
                <a
                  className={styles.link}
                  target='_blank'
                  href='https://jobdone.app/allgemeine-geschaeftsbedingungen/'
                  rel='noreferrer'
                >
                  Terms & Conditions
                </a>
              </Body>
            </FadeInUp>
            <FadeInUp>
              <Body
                component='span'
                variant='small'
                display='inline'
              >
                <a
                  className={styles.link}
                  target='_blank'
                  href='https://jobdone.app/privatsphaere/'
                  rel='noreferrer'
                >
                  Privacy & Policy
                </a>
              </Body>
            </FadeInUp>
            <FadeInUp>
              <Body
                component='span'
                variant='small'
                display='inline'
              >
                <a
                  className={styles.link}
                  target='_blank'
                  href='https://jobdone.app/support/'
                  rel='noreferrer'
                >
                  Contact Us
                </a>
              </Body>
            </FadeInUp>
          </div>
          <div className={styles.flexContainer}>
            <FadeInUp>
              <LangChanger />
            </FadeInUp>
          </div>
        </div>
      </StaggerParent>
    </FadeInOut>
  );
};

export default PageFooterWithTermsAndLangChange;
